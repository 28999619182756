<template>
  <b-container fluid>
    <bo-page-title />
    <section id="webSettings">
      <b-card no-body>
        <template #header>
          <h5 class="card-title">Application Information</h5>
        </template>


        <validation-observer
            v-slot="{ handleSubmit }" ref="VFormSite"
        >
        <b-form @submit.prevent="handleSubmit(submitSettings())">
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-form-group label="Survey Schedule Reminder" label-for="companyInfoEn">
                  <b-input-group append="Day(s)">
                    <b-form-input id="companyInfoEn" v-model="row.hMin" type="number" />
                  </b-input-group>
                    <VValidate 
                        name="Survey Schedule Reminder" 
                        v-model="row.hMin"
                        rules="required|min_value:1" 
                    />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Number of Schedules Shown" label-for="showUpcoming">
                  <b-input-group append="List">
                    <b-form-input type="number" id="showUpcoming" v-model="row.showUpcoming" />
                  </b-input-group>
                    <VValidate 
                        name="Number of Schedules Shown" 
                        v-model="row.showUpcoming"
                        rules="required|min_value:1" 
                    />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Revenue Start Date" label-for="revenueDate">
                  <template v-if="endOfDate">
                    <b-form-input type="number" id="revenueDate" v-model="row.revenueStart" />
                      <VValidate 
                          name="Show Upcoming Schedule" 
                          v-model="row.revenueStart"
                          rules="required|min_value:1|max_value:31" 
                      />
                  </template>
                  <span>{{row.revenueStart}}</span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <div class="text-right" v-if="moduleRole('edit')">
              <!-- <b-button variant="secondary" @click="toLatest()" class="btn-rounded mr-2">Cancel</b-button>               -->
              <b-button variant="info" type="submit" class="btn-rounded">Save Changes</b-button>
            </div>
          </b-card-footer>
        </b-form>
        </validation-observer>
      </b-card>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'

export default {
  extends: GlobalVue,
  data() {
    return {
        noTel: 0,
        row:{},
        websiteSettings:{},
        validationSite: {},
        validationSettings: {},

        companyFooterDesc: 'Malacca Trust Wuwungan Insurance menyediakan beragam solusi produk asuransi jiwa dan umum untuk memenuhi kebutuhan perorangan dan bisnis.',
        defLang: 'Bahasa Indonesia',
        languages: [
            {label : 'Bahasa Indonesia',value : 'ID'},
            {label : 'English ', value : 'EN'},
        ],
        lengthComp : 0,
        lengthTel : 0,
        websiteInformationLatest:{},
        websiteSettingsLatest:{}
    }
  },
  methods: {
    toLatest(){
      this.$swal({
        title: `Do you want to discard this data?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#30d64c',
        confirmButtonText:  `Yes!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
            this.apiGet()
        }
      })
    },

    submitSettings(){
      if(!this.moduleRole('edit')) return
      
      this.$refs.VFormSite.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Update Settings...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-settings', ...this.row} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
              })
          }
        }).catch(() => {
            this.apiGet()
        })
      })
    },
  },
  mounted() {
    this.apiGet()      
  },
  watch:{
    $route(){
        this.apiGet()
    }
  },
  computed: {
    endOfDate(){
      const today = new Date().getDate()
      return today >= 26
    }
  }
}
</script>
